.tag-selection {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: 0s, 0s;
  transition-property: background-color;

  section {
    max-width: 1040px;
    margin: 0 auto;
    align-items: center;
    justify-content: left;

    @media (max-width: 800px) {
      flex-wrap: wrap;
      padding: 0 20px;
      gap: 6px 20px;
    }

    div:first-child {
      @media (max-width: 800px) {
        flex-basis: 100%;
      }
    }
  }
}

.tag-selection-background {
  background-color: #e9c362;
}

.tag-selection-box {
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.176px;
  text-transform: uppercase;
  color: rgb(41, 37, 25);

  gap: 0 20px;

  a {
    color: rgb(41, 37, 25);
  }
}