.index-navigation {
  padding: 48px 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 24px;
  max-width: 1040px;
  margin: 0 auto;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 300px;

    a, em, span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      width: 42px;
      height: 42px;
      color: #272727;
      font-weight: 600;
      text-decoration: none;
      font-size: 24px;
    }

    em {
      background-color:  rgb(234, 207, 138);
    }
  }
}

.has-background {
  background-color: rgb(234, 207, 138);
}