.no-search-results {
  padding: 16px;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.63px;
  font-family: Inter, sans-serif;

  @media (min-width: 800px) {
    padding: 16px 0;
  }
}

.search-result {
  display: flex;
  flex-wrap: wrap;

  border-top: 4px solid #EACF8A;
  margin: 32px 0;
  padding: 20px 0 0;

  @media (min-width: 800px) {
    margin: 64px 0;
    flex-wrap: nowrap;
  }

  a {
    flex-grow: 1;
    flex-basis: 100%;
    text-decoration: none;
  }

  span {
    color: #E9C362;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.456px;
    text-transform: uppercase;
    min-width: 280px;

    @media (max-width: 800px) {
      padding: 0 30px;
      max-width: 0;
    }
  }

  h5 {
    color: #272727;
    font-family: Inter, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.63px;

    @media (max-width: 800px) {
      margin: 25px 0;
      padding: 0 30px;
    }
  }

  p {
    color: #272727;
    font-family: Merriweather, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: -0.336px;

    mark {
      background-color: transparent;
      border-bottom: 3px solid #E9C362;
    }
  }
}