.static-page-content {
  font-family: "Merriweather", serif;
  font-size: 24px;
  max-width: 1040px;
  margin: 30px auto;
  font-style: normal;
  font-weight: 400;
  background-color: white;

  p {
    line-height: 40px;
    margin: 30px auto 0;

    &:first-child {
      margin-top: 0;
    }

    @media (max-width: 500px) {
      font-size: 20px;
    }

    @media (max-width: 800px) {
      padding: 0 30px;
    }
  }

  h1, h3 {
    font-weight: 600;
    font-family: "Inter", sans-serif;
    line-height: 40px;
    margin: 30px auto;
    padding: 0;
    font-size: 30px;

    &:first-child {
      margin-top: 0;
    }

    @media (max-width: 800px) {
      padding: 0 30px;
    }
  }
}

.static-page-navigation {
  margin: 30px auto;

  @media (max-width: 800px) {
    padding-left: 30px;
  }

  a {
    width: 100%;
    color: #272727;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.176px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: left;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transition-property: opacity;
    text-decoration: none;
    gap: 8px;
  }
}

