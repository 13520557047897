.newsletter-lightbox {
  position: relative;
  height: 2px;
  width: 100%;
  z-index: 1000;
}

.newsletter-lightbox-overlay {
  display: none;
  position: fixed;
  background-color: rgba(0,0,0,0.2);
  top: 0;
  left: 0;
  z-index: 1000;
  min-width: 100%;
  min-height: 100vh;
  overflow: hidden;
  transition: ease-in-out;
}

.newsletter-lightbox-dialog {
  position: relative;
  width: 100%;
  z-index: 1000;
  max-width: 1080px;
  margin: 60px auto;
  border: 1px solid #272727;
  font-family: "Inter", sans-serif;
  background-image: url("/images/lightbox-phone.webp"), linear-gradient(135deg, rgb(212, 233, 233) 0%, rgb(212, 233, 233) 14.83%, rgb(229, 242, 242) 14.83%, rgb(229, 242, 242) 29.45%, rgb(245, 250, 250) 29.5%), none;
  background-position: top, 0;
  background-size: contain, auto, auto;
  background-repeat: no-repeat, repeat, repeat;
  background-attachment: scroll, scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  background-color: rgb(212, 233, 233);

  @media (max-width: 600px) {
    margin-top: 60px;
  }
}

.newsletter-lightbox-inner-box {
  position: relative;
  padding: 225px 0 0;
  z-index: 1000;

  @media (max-width: 600px) {
    padding: 0;
  }
}

.newsletter-lightbox-heading {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: right;

  button {
    background-color: transparent;
    cursor: pointer;
  }

  @media (max-width: 400px) {
    svg {
      height: 30px;
      width: auto;
    }
  }
}

.newsletter-lightbox-title-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  h1 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 40px;

    @media (max-width: 600px) {
      font-size: 34px;
    }

    @media (max-width: 400px) {
      font-size: 28px;
    }
  }
}

.newsletter-lightbox-content {
  background-color: white;
  padding: 0 20px 20px;
  font-size: 22px;
  line-height: 1.6;

  div {
    max-width: 700px;
    padding: 15px 0 5px;
    margin: 0 auto;

    @media (max-width: 400px) {
      padding: 10px 0;
    }
  }

  @media (max-width: 800px) {
    font-size: 18px;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }
}

.newsletter-lightbox-signup-quote {
  p:first-child {
    font-style: italic;
  }

  p:last-child {
    margin-top: 10px;
    font-weight: 600;
  }
}

.newsletter-lightbox-signup-blurb {
  a {
    color: inherit;
  }
}

.newsletter-lightbox-signup-form {
  padding-top: 30px;

  form {
    gap: 35px;

    @media (max-width: 800px) {
      gap: 20px;
    }

    @media (max-width: 450px) {
      gap: 15px;
    }

    @media (max-width: 800px) {
      font-size: 18px;
    }

    @media (max-width: 400px) {
      font-size: 14px;
    }

    input {
      background-color: rgb(237, 237, 237) !important;

      @media (max-width: 400px) {
        height: 48px;
      }
    }

    button {
      @media (max-width: 400px) {
        height: 48px;
      }
    }
  }
}