.tag-navigation {
  max-width: 1040px;
  flex-wrap: wrap;
  margin: 60px auto;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 136%;
  color: rgb(120, 120, 120);
  text-align: center;
  font-family: "Inter", sans-serif;

  @media (max-width: 800px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
    font-weight: 500;
    font-size: 24px;
    color: rgb(149, 149, 149);
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(234, 234, 234);
    border-radius: 9.8718px;
    padding: 20px;
    max-width: 340px;
    line-height: 40px;
    transition-duration: 0.3s, 0.3s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
    transition-property: background-color, color;
    text-decoration: none;

    @media (max-width: 800px) {
      font-size: 20px;
      line-height: 30px;
      width: 100%;
    }

    &:hover {
      color: rgb(255, 255, 255);
      background-color: rgb(149, 149, 149);
    }
  }
}