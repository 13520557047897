.search-panel {
  max-width: 1040px;
  margin: 30px auto;
  padding: 0 20px;

  form {
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #EFEFEF;
    height: 48px;
    padding-left: 16px;
  }
}

.search-panel-query {
  flex: 1;
  width: 50%;
  height: 45px;
  border: 0;
  margin: 0 1px 0 16px;
  background: transparent;
  color: #272727;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: -0.34px;
}

.search-panel-submit {
  width: 100px;
  height: 47px;
  border: 0;
  margin: 0;
  border-radius: 0 8px 8px 0;
  background: #E9D585;
  color: #22262B;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

