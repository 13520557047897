.tag-cloud {
  max-width: 1040px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    gap: 20px 0;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
    flex-wrap: wrap;
  }

  & > a {
    padding: 2px 10px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: -0.176px;
    font-style: normal;
    font-weight: 600;
    color: #272727;
    min-width: 300px;
    text-align: right;

    @media (max-width: 800px) {
      flex-basis: 100%;
      text-align: center;
    }
  }
}