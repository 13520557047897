.page-footer {
  padding: 30px 20px;
  background-color: rgb(46, 53, 61);
  font-family: Inter, sans-serif;
  font-style: normal;

  color: rgba(255, 255, 255, 0.76);

  @media (max-width: 800px) {
    padding-bottom: 60px;
  }

  nav {
    display: flex;
    list-style-type: none;
    max-width: 1040px;
    margin: 0 auto;
    gap: 30px;
    padding: 0;

    @media (max-width: 800px) {
      flex-wrap: wrap;
      text-align: center;
    }

    a {
      text-decoration: none;
      font-weight: 300;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.76);
      text-transform: uppercase;
      opacity: 0.4;

      &:hover,
      &:active {
        opacity: 1;
      }

      @media (max-width: 800px) {
        flex-basis: 100%;
      }
    }
  }
}

#page-footer-copyright {
  flex-basis: 50%;
  color: rgba(255, 255, 255, 0.76);
  font-weight: 300;
  font-size: 18px;

  @media (max-width: 800px) {
    flex-basis: 100%;
    padding-bottom: 10px;
  }
}