.zsa-loves-banner {
  position: relative;
  width: 100%;
  padding: 80px 0;
  margin: 0 0 50px;
  background-color: rgba(234, 234, 234, 1);

  div {
    margin: 16px auto 0;
    max-width: 690px;
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    text-align: right;
    height: auto;
    width: 250px;

    @media (max-width: 500px) {
      display: none;
    }
  }

  h2 {
    margin: 0 auto 48px auto !important;
    font-size: 40px;
    font-family: "Inter", sans-serif;
    font-weight: 300;

    & span {
      display: inline-block;
      padding: 10px 24px;
      background-color: rgba(233, 195, 98, 1);
    }
  }

  ul {
    padding-left: 34px !important;
    list-style: none;

    @media (max-width: 690px) {
      padding-left: 40px !important;
      padding-right: 40px;
    }

    li {
      position: relative;
      margin-top: 32px !important;
      font-family: "Merriweather", serif;
      font-weight: 400;
      line-height: 35px;
      color: #272727;

      @media (max-width: 500px) {
        font-size: 20px;
      }

      &:first-of-type {
        margin-top: 0 !important;
      }

      &:before {
        top: 15px;
        left: -34px;
        position: absolute;
        flex: 0 0 7px;
        content: "";
        height: 7px;
        width: 7px;
        background-color: #e9c362;
        border-radius: 50%;
      }

      & a {
        color: rgb(0, 120, 160);
      }

      &:before {
        @media (max-width: 690px) {
          left: -20px !important;
        }
      }
    }
  }
}