.main-navigation-mobile-overlay {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  z-index: 200;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  transition: ease-in-out;
}

.main-navigation-mobile {
  display: none;

  @media (max-width: 1040px) {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: left;
    transition: ease-in-out;

    button {
      border: 0;
      padding: 0;
      margin: 0;
      width: 30px;
      height: 30px;
      overflow: clip; // Image is not square
      background-color: transparent;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.main-navigation-mobile-dropdown {
  position: absolute;
  overflow-y: scroll;
  top: 0;
  left: 0;
  z-index: 300;
  width: 320px;
  max-width: 320px;
  height: 100vh;
  background-color: black;
  color: white;
  transition: ease-in-out;

  & > section {
    display: flex;
    flex-wrap: wrap;
    gap: 0;

    & > a {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-basis: 100%;
      text-decoration: none;
      flex-wrap: nowrap;
      height: 100px;
      padding-left: 12px;
      z-index: 300;
      width: 100%;
      font-size: 14px;
      gap: 10px;

      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 4px;
        flex-basis: 50%;

        span {
          text-align: center;
          flex-basis: 100%;
        }
      }
    }
  }
}

.blog-branding {
  background: #e9c362;
  color: #272727;
}

.voyager-branding {
  background: linear-gradient(90deg, #5f63d6 0%, #2b3380 100%);
  color: white;
  padding-right: 12px;
}

.moonlander-branding {
  background: linear-gradient(270deg, #32475a 0%, #395b6e 100%);
  color: white;
  padding-right: 12px;
}

.ergodox-branding {
  background: linear-gradient(270deg, #6db4c7 0%, #9cd2db 134.59%);
  color: white;
  padding-right: 6px;
}

.ergo-branding {
  background: #498686;
  color: white;
  gap: 0 20px;
}

.show-overlay {
  display: block !important;
}

.no-scroll {
  overflow: hidden;
}
