.tag-link {
  background-color: rgb(244, 244, 244);
  padding: 4px 12px;
  text-decoration: none;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.176px;
  text-transform: uppercase;
  color: #272727;
}