.article-navigation {
  display: none;
  background: #e9c362;
  top: 0;
  width: 100%;
  position: fixed;
  padding: 6px 32px 8px;
  z-index: 400;
  transition: opacity ease 0.3s;
  opacity: 0;
  justify-content: center;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 18px;
    width: 100%;
    max-width: 1040px;

    @media (max-width: 800px) {
      font-size: 16px;
    }

    a {
      text-decoration: none;
      gap: 5px;
      color: #272727;
      cursor: pointer;
    }
  }
}

.article-navigation-arrow-box {
  min-width: 80px;

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    font-weight: 600;
  }
}

.article-navigation-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 42px;

  & > a {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 50px;
    border-right: 1px solid #272727;
    text-transform: uppercase;
    font-weight: 600;

    @media (max-width: 800px) {
      border-right: 0;
      padding-right: 0;
    }
  }

  & > div {
    font-weight: 400;
    display: flex;
    gap: 4px;

    @media (max-width: 800px) {
      display: none;
    }
  }
}