.article-summary {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  margin: 20px 0;
  border-top-width: 4px;
  border-top-style: solid;
  border-top-color: rgb(234, 207, 138);
  padding: 40px 20px 0;

  &:last-child {
    border-bottom-width: 4px;
    border-bottom-style: solid;
    border-bottom-color: rgb(234, 207, 138);
  }

  @media (max-width: 800px) {
    padding: 40px 0 0;
    flex-wrap: wrap;
  }

  h3 {
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 155px;
    top: 72px;
    padding: 0 0 40px;
    position: sticky;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: rgb(233, 195, 98);
    text-transform: uppercase;
    margin: 0;

    @media (max-width: 800px) {
      position: static;
      flex-basis: 100%;
      text-align: center;
      padding: 0 30px;
    }
  }

  article {
    margin-bottom: 85px;

    a {
      text-decoration: none;
    }

    h2 {
      margin: 20px 0;
      font-family: Inter, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 40px;
      color: #272727;

      @media (max-width: 800px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    p {
      font-family: Merriweather, serif;
      font-style: normal;
      font-size: 20px;
      line-height: 32px;
      color: #272727;

      @media (max-width: 800px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    img {
      display: block;
      max-width: 100%;

      @media (max-width: 800px) {
        aspect-ratio: 1/1;
        object-fit: cover;
      }
    }
  }
}

.article-summary-tag-box {
  display: flex;
  align-items: flex-start;
  justify-content: right;
  gap: 10px;
}