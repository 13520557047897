.article-header-image {
  position: fixed;
  display: block;
  max-width: 100%;
  top: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  pointer-events: none;
  transition: opacity ease;
  object-position: 50% 50%;
  opacity: 1;
  z-index: 25;

  @media (max-width: 800px) {
    width: 100vw;
    height: 100vw;
    aspect-ratio: 1/1;
    padding-bottom: 24px;
    z-index: 2;
  }
}

