.four-oh-four {
  background-image: url("/images/404.webp");

  a {
    color: white;

    &:hover {
      opacity: 0.7;
    }
  }
}