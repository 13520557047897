.main-navigation {
  top: 0;
  left: 0;
  z-index: 110;
  background-color: rgb(46, 53, 61);
  padding: 8px 0;
  width: 100%;
  font-family: "Inter", sans-serif;
  height: 58px;
  display: flex;
  align-items: center;

  @media (max-width: 1040px) {
    padding: 10px;
  }

  & > nav {
    max-width: 1040px;
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
    width: 100%;

    @media (max-width: 1040px) {
      justify-content: center;
    }

    & > section {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 30px;

      @media (max-width: 1040px) {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.76);
      text-transform: uppercase;
      opacity: 0.4;

      &:hover,
      &:active {
        opacity: 1;
      }
    }
  }
}

#main-navigation-logo {
  font-family: "Inter", sans-serif;
  font-weight: 200;
  text-transform: uppercase;
  font-style: normal;
  letter-spacing: 5px;
  font-size: 24px;
  opacity: 1;
  color: white;

  svg {
    fill: white;
    stroke: white;
    height: 28px;
  }
}

#main-navigation-newsletter {
  display: flex;
  align-items: center;
  justify-content: right;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 18px;
  opacity: 1;
  color: white;
  gap: 12px;
  height: 36px;
  padding: 0 12px;

  @media (max-width: 1040px) {
    display: none;
  };

  &:hover {
    background-color: white;
    color: black;
    border-radius: 2px;

    svg {
      fill: black;
      stroke: black;
    }
  }
}
