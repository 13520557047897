.blog-page {
  header {
    position: absolute;
    background-color: transparent;
  }
}

.blog-page-post {
  margin: 0;
  border: 0;
  padding-top: 100vh;

  @media (max-width: 500px) {
    padding-top: 100vw;
  }
}
