.newsletter-signup {
  position: relative;
  background-color: #ededed;
  font-family: Inter, sans-serif;

  @media (max-width: 800px) {
    padding: 0 32px;
  }

  span {
    font-size: 14px;
    line-height: 16px;
    color: #272727;
  }
}

.newsletter-signup-box {
  max-width: 750px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.newsletter-signup-icon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.newsletter-signup-quote {
  margin: 0;
  padding-top: 80px;
  font-style: italic;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3333333;

  @media (min-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 800px) {
    text-align: center;
  }
}

.newsletter-signup-blurb {
  margin: 32px 0 20px;
  color: #5e5e5e;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.5;

  a {
    color: #5e5e5e;
  }
}

.newsletter-signup-form {
  padding-top: 10px;

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    width: 100%;
    gap: 15px 30px;
    margin-bottom: 5px;
    flex-wrap: wrap;

    @media (max-width: 800px) {
      gap: 30px;
    }

    input {
      background-color: white;
      color: #272727;
      border: 0;
      border-radius: 4px;
      padding: 12px 24px;
      font-size: 100%;
      line-height: 1.15;
      flex-grow: 2;
      flex-basis: 35%;

      &::placeholder {
        color: #272727;
      }

      @media (max-width: 800px) {
        width: 100%;
        flex-basis: 100%;
        height: 64px;
      }

      .invalid {
        background: #ffaaaa;
      }

      &.success, &.submitting {
        opacity: 0;
      }
    }

    span {
      order: 1;
      flex-basis: 100%;

      @media (max-width: 800px) {
        text-align: center;
        order: 0;
      }

      @media (max-width: 400px) {
        font-size: 14px;
      }
    }

    button {
      cursor: pointer;
      outline: inherit;
      border: none;
      background: none;
      font: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 3.2px 4.9px 0 rgba(0, 0, 0, 0.25);
      background: rgb(50, 53, 70);
      color: #ffffff;
      padding: 12px 24px;
      gap: 10px;
      flex-basis: 30%;

      @media (max-width: 800px) {
        width: 100%;
        flex-basis: 100%;
        height: 64px;
      }
    }

    a {
      color: #272727;

      @media (max-width: 800px) {
        width: 100%;
        flex-basis: 100%;
        text-align: center;
      }
    }
  }
}