.soundcloud {
  padding: 30px 0;

  p {
    margin-top: 0;

    &:first-letter {
      float: left;
      font-size: 91px;
      padding: 22px;
      line-height: 69%;
      text-transform: capitalize;
      background: #e9c362;
      border: 15px solid #ffffff;
      border-left: none;
      border-top-width: 6px;
      border-bottom-width: 2px;

      @media (max-width: 500px) {
        font-size: 60px;
        padding: 12px;
      }
    }

    a {
      text-decoration: none;
      color: #cccccc;

      &:hover {
        color: #cccccc;
      }
    }
  }
}

.contain-image {
  max-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  @media (max-width: 800px) {
    height: 400px;
  }

  img {
    max-height: inherit;
  }
}