.banner-header {
  min-height: 80vh;
  background-image: url("/images/ergo-home-background.webp");
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-width: 10px;
  border-bottom-style: solid;
  border-bottom-color: rgb(233, 195, 98);
  transition: ease-in-out;

  div {
    max-width: 1350px;
    margin-left: auto;
    margin-right: auto;
    padding: 256px 20px 100px;
    width: 100%;

    @media (max-width: 500px) {
      padding: 160px 20px 0;
    }

    svg {
      max-width: 100%;
      margin-right: auto;
    }

    h2 {
      display: block;
      padding-top: 40px;
      font-size: 48px;
      font-family: "Merriweather", serif;
      max-width: 800px;

      @media (max-width: 800px) {
        font-size: 32px;
      }
    }

    h3 {
      padding: 40px 0 60px;
      font-size: 24px;
      font-family: "Inter", sans-serif;

      @media (max-width: 800px) {
        font-size: 22px;
      }
    }
  }
}