.tag-header {
  background-size: cover;
  width: 100%;
  padding: 64px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 32px;
  text-decoration: none;

  h2 {
    font-family: Inter, sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.299px;
    text-transform: uppercase;
    padding:  6px 28px;
    background-color: rgb(244, 244, 244);
    box-shadow: rgba(0, 0, 0, 0.25) 0 4px 4px 0;
    color: #272727;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    transition-property: box-shadow;
    text-align: center;
  }
}