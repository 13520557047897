.blog-article {
  position: relative;
  font-family: "Merriweather", serif;
  font-size: 24px;
  max-width: 1040px;
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  z-index: 20;
  background-color: white;
}

.blog-article-metadata {
  line-height: 40px;
  margin: 0 auto;
  max-width: 690px;
  width: 100%;
  padding: 12px 30px 30px;

  h1 {
    margin: 0 0 18px;
    font-family: "Merriweather", serif;
    font-weight: 800;
    font-size: 60px;
    line-height: 80px;
    color: #272727;

    @media (max-width: 800px) {
      font-size: 48px;
    }

    @media (max-width: 500px) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  h2 {
    margin: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 174%;
    color: #272727;

    @media (max-width: 800px) {
      font-size: 22px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  h3 {
    display: block;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    color: #272727;
    font-weight: 400;
  }
}

.blog-article-content {
  text-rendering: optimizeLegibility;
  color: #272727;

  @media (max-width: 500px) {
    font-size: 20px;
    padding: 0;
  }

  img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  figure {
    margin: 16px 0;
    padding: 0;
    border: 0;

    img {
      display: block;
      height: auto;
      margin: 0 auto;
      box-shadow: rgba(0, 0, 0, 0.27) 0 1px 7px, rgba(0, 0, 0, 0.06) 0 5px 17px;
    }

    figcaption {
      margin: 16px 0;
      font-size: 16px;
      font-style: italic;
      text-align: center;
    }
  }

  table {
    table-layout: fixed;
    font-size: 24px;
    line-height: 40px;
    margin: 36px auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 690px;
    width: 100%;

    td, th {
      text-align: left;
      vertical-align: top;
      padding: 15px 5px;

      @media (max-width: 500px) {
        padding: 20px 5px;
      }
    }
  }

  p {
    line-height: 40px;
    margin: 36px auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 690px;
    width: 100%;

    &:first-child {
      margin-top: 0;
    }

    @media (max-width: 500px) {
      font-size: 20px;
    }

    &:first-of-type::first-letter {
      float: left;
      font-size: 91px;
      padding: 22px 23px 21px 25px;
      line-height: 69%;
      text-transform: capitalize;
      background: #e9c362;
      border: 15px solid #ffffff;
      border-left: none;
      border-top-width: 6px;
      border-bottom-width: 2px;

      @media (max-width: 500px) {
        font-size: 60px;
        padding: 11px 11px 11px 12px;
      }
    }
  }

  ul {
    list-style: none;

    li {
      &:before {
        top: 15px;
        left: -34px;
        position: absolute;
        flex: 0 0 7px;
        content: "";
        height: 7px;
        width: 7px;
        background-color: #e9c362;
        border-radius: 50%;
      }
    }
  }

  ul,
  ol {
    line-height: 40px;
    max-width: 690px;
    margin: 16px auto;
    padding-left: 46px;
    padding-right: 30px;
    overflow: hidden;

    li {
      position: relative;
      margin-top: 16px;
    }

    @media (max-width: 500px) {
      font-size: 20px;
    }
  }

  ul {
    line-height: 35px;
    font-weight: 400;
    max-width: 690px;
    margin-left: auto;
    margin-right: auto;

    &:first-child {
      margin-top: 0;
    }
  }

  blockquote,
  li {
    padding-bottom: 0.2rem;
    margin-bottom: 0;
    max-width: 690px;
    margin-left: 30px;
    margin-right: 30px;

    p::first-letter {
      float: none !important;
      padding: 0 !important;
      text-transform: none !important;
      background: none !important;
      border: none !important;
      font-size: 24px !important;
      line-height: 40px !important;

      @media (max-width: 500px) {
        font-size: 20px !important;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    font-family: "Inter", sans-serif;
    padding-bottom: 0;
    line-height: 133.5%;
    max-width: 690px;
    padding-left: 30px;
    padding-right: 30px;
    margin: 64px auto 36px;

    &:first-child {
      margin-top: 0;
    }
  }

  h1 {
    font-size: 44px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 34px;
  }

  h4 {
    font-size: 30px;
  }

  h5 {
    font-size: 28px;
  }

  h6 {
    font-size: 26px;
  }

  a {
    text-decoration: underline;
    color: #30add4;
    line-height: inherit;
    transition: 0.3s ease-in-out color;

    &:hover {
      color: #0078a0;
    }
  }

  iframe,
  video,
  code {
    max-width: 100%;
  }

  pre {
    box-shadow: inset 4px 4px 7px rgba(0, 0, 0, 0.086), inset 0 4px 45px rgba(255, 255, 255, 0.35);
    border-radius: 4px;
    margin: 36px auto;
    max-width: 800px;
    padding: 5px 30px;
    background: #f3f3f3;

    @media (max-width: 500px) {
      max-width: 100%;
      margin: 36px 25px;
      overflow-x: scroll;
      padding: 5px 5px 5px 15px;
    }

    code {
      font-size: 80%;

      @media (max-width: 400px) {
        font-size: 70%;
      }
    }
  }

  blockquote {
    font-family: "Merriweather", serif;
    font-size: 55px;
    line-height: 118%;
    color: #272727;
    margin: 0 32px 20px;
    padding: 0;

    p {
      font-family: "Merriweather", serif;
      margin: 0;

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &:first-letter {
        font: inherit !important;
      }
    }

    @media (max-width: 800px) {
      font-size: 40px;
    }
  }
}

.blog-article-author {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 30px;
  margin: 30px auto;
  max-width: 690px;
  width: 100%;
  padding: 30px 32px 0;
  color: #272727;
  font-family: Inter, sans-serif;
  font-style: normal;

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }

  img {
    display: block;
    border-radius: 50%;
    overflow: hidden;
    max-width: 160px;
    min-width: 160px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }

  h4 {
    margin: 0 0 20px;
    padding: 0;
    font-weight: 300;
    font-size: 28px;
    line-height: 100%;
  }

  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 175%;
  }
}

.blog-article-update {
  font-family: Merriweather, serif;
  margin: 40px 0;
  padding: 10px 16px;
  line-height: 1.5;
  background-color: rgb(233, 195, 98);
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 16px;
}