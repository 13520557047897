.index-page {
  main {
    max-width: 1040px;
    margin: 0 auto;
  }
}

.tag-page {
  padding-top: 1px;
  position: relative;

  header {
    position: absolute;
  }
}

.home-page {
  header {
    position: fixed !important;
  }
}